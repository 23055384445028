import React, { useState, useMemo, useEffect } from "react"; // Add React import if missing
import { useGetAllCategoriesWithSubcategoriesQuery } from "../../redux/Apis/Category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../redux/Slices/Category/index"; // Action to set categories in Redux
import { icons } from "lucide-react"; // Import all icons
import "./MobileMegaMenu.css";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MobileMegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch categories from Redux state
  const storedCategories = useSelector((state) => state.category.categories);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null); // Track active category for subcategory toggle

  // Fetch categories from the API (no skip, always refetch)
  const {
    data: response,
    error,
    isLoading,
  } = useGetAllCategoriesWithSubcategoriesQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const categories = useMemo(() => storedCategories, [storedCategories]);

  useEffect(() => {
    if (response?.data) {
      dispatch(setCategories(response.data)); // Update Redux with new data
    }
  }, [response, dispatch]);

  const handleCategoryClick = (categoryId, subcategoryId = "") => {
    navigate(
      `/products?category_id=${categoryId}${
        subcategoryId ? `&subcategory_id=${subcategoryId}` : ""
      }`
    );
    setMobileMenuOpen(false);
  };

  const handleCategoryToggle = (categoryId) => {
    setActiveCategory((prevCategoryId) =>
      prevCategoryId === categoryId ? null : categoryId
    );
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="mobile-menu-container">
      <li className="menu-item" onClick={toggleMobileMenu}>
        {t("Products")}
      </li>

      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <button className="close-button" onClick={toggleMobileMenu}>
            ✕
          </button>
        </div>
        <div className="categories-list" style={{ marginTop: "50px" }}>
          {response?.data?.map((category) => (
            <Accordion key={category._id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${category._id}-content`}
                id={`panel${category._id}-header`}
              >
                {category?.iconName ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${category.iconName}`}
                    alt={`${category.name} icon`}
                    className="category-icon-nav"
                  />
                ) : (
                  <span
                    className="default-icon"
                    style={{
                      marginRight: "15px",
                      height: "50px",
                      width: "50px",
                    }}
                  >
                    🔍
                  </span>
                )}
                <Typography sx={{marginY: "auto"}}>{category.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="subcategory-list">
                  {category.subcategories?.map((subcategory) => (
                    <Typography
                      key={subcategory._id}
                      className="subcategory-item"
                      onClick={() =>
                        handleCategoryClick(category._id, subcategory._id)
                      }
                      style={{ cursor: "pointer", padding: "5px 0" }}
                    >
                      {subcategory.name}
                    </Typography>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMegaMenu;
