import React, { useState } from "react";
import { StandardInputTheme } from "../Auth/StandardInputTheme";
import { ThemeProvider } from "@emotion/react";
import { TextField } from "@mui/material";
import AddressList from "./AddressList";

const AddressForm = () => {
  const [showForm, setShowForm] = useState(false)
  const handleToggleForm = () => {
    setShowForm(!showForm)
  }
  return (
    <>
      <h2 className="contact-form-heading mb-0 w-100 text-center">Hantera adress</h2>

      <div className="my-3 w-100">
        <AddressList />
      </div>
    </>

  );
};

export default AddressForm;
