import React, { useState, useEffect } from "react";
import "./NewsletterModal.css";
import {
  useGetNewsletterQuery,
  useSubscribeToNewsletterMutation,
} from "../../redux/Apis/Newsletter";
import { toast } from "react-toastify";

const NewsletterModal = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [email, setEmail] = useState("");
  const { data: newsletterData, isLoading, error } = useGetNewsletterQuery();
  const [subscribeToNewsletter] = useSubscribeToNewsletterMutation();

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("newsletterClosed", "true");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newsletter?._id && email) {
      try {
        await subscribeToNewsletter({ id: newsletter._id, email }).unwrap();
        toast.success("Tack för att du prenumererar på Swedebuy");
        setIsVisible(false);
        localStorage.setItem("newsletterClosed", "true");
      } catch (error) {
        console.error("Subscription failed:", error);
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    const newsletterClosed = localStorage.getItem("newsletterClosed");
    if (newsletterClosed === "true") {
      setIsVisible(false);
    }
  }, []);

  if (!isVisible) return null;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading newsletter data</div>;

  const newsletter = newsletterData?.data?.[0]; // Access the first item in the data array

  return (
    <div className="newsletter-modal-overlay">
      <div className="newsletter-modal">
        <button className="close-button" onClick={handleClose}>
          &times;
        </button>
        <div className="newsletter-content">
          <div className="newsletter-image">
            <img
              src={
                `${process.env.REACT_APP_IMAGE_BASE_URL}/${newsletter?.imageUrl}` ||
                "https://djangogreatkart.com/static/images/banners/cover.jpg"
              }
              alt="Newsletter"
            />
          </div>
          <div className="newsletter-text">
            <h2>{newsletter?.title || "UP TO 30% OFF"}</h2>
            <p>
              {newsletter?.description ||
                "Subscribe to the SWEDEBUY eCommerce newsletter to receive timely updates from your favorite products."}
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Ange din e-postadress"
                className="newsletter-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="newsletter-submit">
                Skicka
              </button>
            </form>
            <div className="newsletter-social">
              {/* Add social icons here if needed */}
            </div>
            <div className="newsletter-no-thanks">
              <button
                type="button"
                onClick={handleClose}
                style={{
                  backgroundColor: "#ff4d4f",
                  color: "#fff",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  fontSize: "16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor = "#ff7875")
                }
                onMouseOut={(e) => (e.target.style.backgroundColor = "#ff4d4f")}
              >
                Nej tack
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterModal;
