import React from "react";
import { useNavigate } from "react-router-dom";
import productImage from "../../shared/images/no-image.jpg";
import "./ProductCard.css";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useDispatch } from 'react-redux';
import { addItemToCart } from '../../redux/Slices/Cart';
import { openCartModal } from '../../redux/Slices/CartModal';
import { useTranslation } from 'react-i18next';


const ProductCard = ({ data }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
    const { t } = useTranslation();

    // Determine if the product has variations and options
    const hasPriceVariations = data?.price_variation && data.price_variation.length > 0;
    const hasVariants = data?.variants && data.variants.length > 0 && data.variants[0].options.length > 0;

    // Find the lowest price variation
    const lowestVariation = hasPriceVariations
        ? data.price_variation.reduce((min, curr) => {
            const currDiscountedPrice = data.discount
                ? curr.priceInSek * (1 - data.discount.percentage / 100)
                : curr.priceInSek;
            return parseFloat(currDiscountedPrice) < parseFloat(min.discountedPrice) ? { ...curr, discountedPrice: currDiscountedPrice } : min;
        }, { ...data.price_variation[0], discountedPrice: data.price_variation[0].priceInSek })
        : { scale: "default", price: 0, discountedPrice: "0.00" };

    // Select a random color if variants are available
    const randomColor = hasVariants ? data.variants[0].options[Math.floor(Math.random() * data.variants[0].options.length)].option : "No color available";

    const handleCardClick = () => {
        navigate(`/product/${data?._id}`);
    };

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, i) => (
            i < rating ? <StarIcon key={i} className="star filled" /> : <StarBorderIcon key={i} className="star" />
        ));
    };

    const handleAddToCart = (e) => {
        e.stopPropagation();
        const availableStock = getLatestStockQuantity(data.stock.stocks);
        // Create product object to add to cart with lowest price, random color, and quantity discount
        const productToAdd = {
            productId: data._id,
            sku: data.sku,
            isBigBuyProduct: !!data.bigbuyId,
            name: data.name,
            size: lowestVariation.scale,
            price: parseFloat(lowestVariation.discountedPrice) || parseFloat(lowestVariation.priceInSek),

            quantity: 1,
            imageUrl: data.images.length > 0 ? `${data.images[0]}` : productImage,
            discount: data.discount ? data.discount.percentage : null, // Include percentage discount if available
            quantityDiscount: data.quantity_discount ? { // Include quantity discount if available
                quantity: data.quantity_discount.quantity,
                discount: data.quantity_discount.discount
            } : null
        };

        dispatch(addItemToCart({
            ...productToAdd,
            stock: availableStock // Include the available stock in the productToAdd object
        }));
        dispatch(openCartModal()); // Open cart modal
    };

    const getLatestStockQuantity = (stocks) => {
        if (!stocks || stocks.length === 0) return "No stock available";

        // Get the stock entry with the highest quantity
        const latestStock = stocks.reduce((prev, current) => (prev.quantity > current.quantity) ? prev : current);

        return latestStock.quantity > 0 ? latestStock.quantity : "Out of Stock";
    };

    return (
        <div
            className="product-component-card-2"
            onClick={handleCardClick}
        >
            <div className="product-image-section-2">
                {data?.bigbuyId ? (
                    <img
                        src={data?.images[0]}
                        alt={data?.name || "Product"}
                        className="product-component-image-2"
                    />
                ) : (
                    data?.images && (
                        <img
                            src={data?.images?.length > 0 ? `${baseUrl}${data.images[0]}` : productImage}
                            alt={data?.name || "Product"}
                            className="product-component-image-2"
                        />
                    )
                )}
            </div>
            <div className="product-details-section-2">
                <div className="product-component-info-2">
                    <h4 className="product-component-title-2">{data?.name}</h4>
                    {/* <div className="product-component-rating-2">
                        {renderStars(data?.rating || 4)}
                    </div> */}
                </div>
                <div className="product-component-pricing-2">
                    {lowestVariation && (
                        <>
                            <p className="product-component-price-2">
                                {parseFloat(lowestVariation.discountedPrice) < parseFloat(lowestVariation.priceInSek) ? (
                                    <>
                                        <span className="original-price-2">
                                            {parseFloat(lowestVariation.priceInSek).toFixed(2)} kr
                                        </span>
                                        <span className="discounted-price-2">
                                            {parseFloat(lowestVariation.discountedPrice).toFixed(2)} kr
                                        </span>
                                    </>
                                ) : (
                                    <span>{parseFloat(lowestVariation.priceInSek).toFixed(2)} kr</span>
                                )}
                            </p>
                        </>
                    )}
                </div>

                <div className="btn-wrap-2">
                    <button
                        className="btn-2 btn-dark-2"
                        onClick={handleAddToCart}
                    >
                        <LocalMallOutlinedIcon className="cart-icon" /> Lägg i korgen
                    </button>
                </div>
            </div>
        </div>
    );

};

export default ProductCard;
